import github from './images/github.png';
import linkedin from './images/linkedin2.png';

export const buttonActions = [
  {
    label: 'GitHub',
    img: github,
    width: '50%',
    url: 'https://github.com/alvloureiro',
  },
  {
    label: 'LinkedIn',
    img: linkedin,
    width: '50%',
    url: 'https://www.linkedin.com/in/alvloureiro/',
  },
];

export let content = "Software Engineer currently work with Android Automotive Opensource Project(AAOS) " +
              "focusing on custom vehicle properties to Vehicle HAL layer. Also has experience as " +
              "technical leader for web applications that use ReactJS, NodeJS, ExpressJS and Mongodb " +
              "as main tech stack, besides my aforementioned works, I have experience with Android "+
              "Application development using modern tech stack like Kotlin, MVVM and Firebase, " +
              "multimedia solutions for embedded applications and mobile applications, both using " +
              "Qt framework.";
